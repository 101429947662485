<template>
  <div class="login col-6 m-auto mt-5">
    <div class="form-group">
      <label for="">{{ $t('changeFirstTimePassword.password_now') }}</label>
      <Input id="password_now"
             :placeholder="$t('changeFirstTimePassword.password_now')"
             :error-messages="errors.password_now"
             type="password"
             :value="dataChangePass.password_now"
             @onHandleChangeValue="(value) => onHandleChange('password_now', value)"/>
    </div>
    <div class="form-group">
      <label for="">{{ $t('changeFirstTimePassword.password') }}</label>
      <Input id="password"
             :placeholder="$t('changeFirstTimePassword.password')"
             :error-messages="errors.password"
             type="password"
             :value="dataChangePass.password"
             @onHandleChangeValue="(value) => onHandleChange('password', value)"/>
    </div>
    <div class="form-group">
      <label for="">{{ $t('changeFirstTimePassword.password_confirm') }}</label>
      <Input id="password_confirm"
             :placeholder="$t('changeFirstTimePassword.password_confirm')"
             :error-messages="errors.password_confirm"
             type="password"
             :value="dataChangePass.password_confirm"
             @onHandleChangeValue="(value) => onHandleChange('password_confirm', value)"/>
    </div>
    <Button @click="changePassword" class-name="btn btn-primary">
      {{ $t('resetPassword.send') }}
    </Button>
  </div>
</template>

<script>
import AuthRepository from "@/services/api/AuthRepository";
import { SUCCESS } from "@/constants/http_code";
import Input from "@/components/common/Input";
import { submitChangePassFormSchema } from "@/common/schemaValidation";
import Button from "@/components/common/Button";
import { mapGetters } from "vuex";
import localStorage from "@/common/localStorage";

export default {
    name: "ChangePassword",
    components: {
        Input,
        Button
    },
    data() {
        return {
            dataChangePass: {
                user_id: null,
                password_now: "",
                password: "",
                password_confirm: ""
            },
            errors: {
                password_now: null,
                password: null,
                password_confirm: null
            },
        };
    },
    computed: {
        ...mapGetters({
            user: "common/getUser"
        })
    },
    methods: {
        changePassword() {
            this.dataChangePass.user_id = this.user.id;
            submitChangePassFormSchema
                .validate(this.dataChangePass, { abortEarly: false })
                .then(() => {
                    this.errors = {};
                    AuthRepository.ChangePassword(this.dataChangePass)
                        .then((res) => {
                            if (!res.status) {
                                this.$toast.error(res.error.message, {})
                            }
                            if (res.status === SUCCESS) {
                                localStorage.saveInfo(JSON.stringify(res.data.data));
                                this.$toast.open({
                                    message: "パスワード変更の登録が完了しました。",
                                    type: "success",
                                });
                                this.$router.push({ name: "dashboard" });
                            } else {
                                this.$toast.open({
                                    message: "パスワード変更処理中にエラーが発生しました。",
                                    type: "error",
                                });
                            }
                        })
                })
                .catch(err => {
                    localStorage.destroyInfo();
                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        onHandleChange(field, value) {
            this.dataChangePass[field] = value
            this.validate(field)
        },
        validate(field) {
            submitChangePassFormSchema
                .validateAt(field, this.dataChangePass)
                .then(() => {
                    this.errors[field] = ""
                })
                .catch(err => {
                    this.errors[field] = err.message
                });
        },
    }
};
</script>
